<template>
  <mj-manifest/>
</template>

<script>
import Manifest from "@/components/manifest/Manifest_components.vue";

export default {
  components: {
    mjManifest: Manifest
  }
};
</script>
