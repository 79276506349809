<template>
  <b-container fluid>
    <b-row>
      <b-col cols="0" sm="0" md="0" lg="1" xl="2"/>
      <b-col cols="12" sm="12" md="12" lg="10" xl="8">
        <div>
          <div
            class="manifest__main"
            v-resize-text="{ratio:2.1, minFontSize: '30px'}"
          >
            <div class="manifest__title">Manifest:&nbsp;</div>
            <div class="manifest__content">
              We have
              <span :class="{ manifest__strong: effects.strongEffect }">strong </span>
              <span :class="{ manifest__blur: effects.blurEffect }">vision</span>
              to &quot;change the world&quot; from society where people have
              <span :class="{ manifest__little: effects.littleEffect }"
                >little</span
              >
              hope for control over their pe{{ transform1 }}al d{{ transform2 }}a to
              one in which the expectation, and reality of control is
              <span v-if="effects.partsEffect">
                p
                <sub>a</sub>
                <sup>r</sup>
                <sub>t</sub>
              </span>
              <span v-else>part</span>
              of
              <span :class="{ manifest__transparent: effects.transparentEffect }"
                >transparent</span
              >
              and responsible commercial transactions.
            </div>
          </div>
          <div class="manifest__note">
            <small
              >Inspiration: "THE TRADE OFF FALLACY", How Marketers Are
              Misrepresenting American Consumers And Opening Them Up to Exploitat.;
              Joseph Turow, Annenberg School for Commnuication University of
              Pennsylvania; Michael Hennessy, Annenberg Public Policy Center,
              University of Pennsylvania; Nora Draper, Department of Communication;
              2015, A Report from the Annenberg School for Commnuication, University
              of Pennsylvania, page 5</small
            >
          </div>
        </div>
      </b-col>
      <b-col cols="0" sm="0" md="0" lg="1" xl="2"/>    
    </b-row>
    <!-- <b-row class="section-background">
      <b-col cols="0" sm="0" md="0" lg="1" xl="2"/>
      <b-col cols="12" sm="12" md="12" lg="10" xl="8">
        <div class="manifest__blue__pool" v-if="windowWidth > 767">
          <div class="pool__1" ref="pool__1" v-on:click="clickOnCard('pool__1')">
            <b-card
              :title="manifestContent[0].head_big"
              header-tag="header"
              footer-tag="footer"
            >
              <template v-slot:header>
                <h6 class="mb-0">{{ manifestContent[0].head_small }}</h6>
              </template>
              <scrolly class="foo" :style="{ width: '100%', height: '250px' }">
                <scrolly-viewport>
                  <div class="technical_data_body_main">
                    <b-card-text>
                      {{ manifestContent[0].content }}
                    </b-card-text>
                  </div>
                </scrolly-viewport>
                <scrolly-bar axis="y"></scrolly-bar>
                <scrolly-bar axis="x"></scrolly-bar>
              </scrolly>
            </b-card>
          </div>
          <div class="pool__2" ref="pool__2" v-on:click="clickOnCard('pool__2')">
            <b-card
              :title="manifestContent[1].head_big"
              header-tag="header"
              footer-tag="footer"
            >
              <template v-slot:header>
                <h6 class="mb-0">{{ manifestContent[1].head_big }}</h6>
              </template>
              <scrolly class="foo" :style="{ width: '300px', height: '250px' }">
                <scrolly-viewport>
                  <div class="technical_data_body_main">
                    <b-card-text>{{ manifestContent[1].content }}</b-card-text>
                  </div>
                </scrolly-viewport>
                <scrolly-bar axis="y"></scrolly-bar>
                <scrolly-bar axis="x"></scrolly-bar>
              </scrolly>
            </b-card>
          </div>
          <div class="pool__3" ref="pool__3" v-on:click="clickOnCard('pool__3')">
            <b-card
              :title="manifestContent[2].head_big"
              header-tag="header"
              footer-tag="footer"
            >
              <template v-slot:header>
                <h6 class="mb-0">{{ manifestContent[2].head_big }}</h6>
              </template>
              <scrolly class="foo" :style="{ width: '400px', height: '150px' }">
                <scrolly-viewport>
                  <div class="technical_data_body_main">
                    <b-card-text>{{ manifestContent[2].content }}</b-card-text>
                  </div>
                </scrolly-viewport>
                <scrolly-bar axis="y"></scrolly-bar>
                <scrolly-bar axis="x"></scrolly-bar>
              </scrolly>
            </b-card>
          </div>
        </div>

        <div class="manifest__blue__pool" v-if="windowWidth < 768">
          <div class="pool__2" ref="pool__2" v-on:click="clickOnCard('pool__2')">
            <b-card
              :title="manifestContent[1].head_big"
              header-tag="header"
              footer-tag="footer"
            >
              <template v-slot:header>
                <h6 class="mb-0">{{ manifestContent[1].head_big }}</h6>
              </template>
              <scrolly class="foo" :style="{ width: '250px', height: '300px' }">
                <scrolly-viewport>
                  <div class="technical_data_body_main">
                    <b-card-text>{{ manifestContent[1].content }}</b-card-text>
                  </div>
                </scrolly-viewport>
                <scrolly-bar axis="y"></scrolly-bar>
                <scrolly-bar axis="x"></scrolly-bar>
              </scrolly>
            </b-card>
          </div>

          <swiper :options="swiperOption">
            <swiper-slide>
              <div
                class="pool__1"
                ref="pool__1"
                v-on:click="clickOnCard('pool__1')"
              >
                <b-card
                  :title="manifestContent[0].head_big"
                  header-tag="header"
                  footer-tag="footer"
                >
                  <template v-slot:header>
                    <h6 class="mb-0">{{ manifestContent[0].head_small }}</h6>
                  </template>
                  <scrolly class="foo" :style="{ width: '250px', height: '300px' }">
                    <scrolly-viewport>
                      <div class="technical_data_body_main">
                        <b-card-text>{{ manifestContent[0].content }}</b-card-text>
                      </div>
                    </scrolly-viewport>
                    <scrolly-bar axis="y"></scrolly-bar>
                    <scrolly-bar axis="x"></scrolly-bar>
                  </scrolly>
                </b-card>
              </div>
            </swiper-slide>

            <swiper-slide>
              <div
                class="pool__3"
                ref="pool__3"
                v-on:click="clickOnCard('pool__3')"
              >
                <b-card
                  :title="manifestContent[2].head_big"
                  header-tag="header"
                  footer-tag="footer"
                >
                  <template v-slot:header>
                    <h6 class="mb-0">{{ manifestContent[2].head_big }}</h6>
                  </template>
                  <scrolly class="foo" :style="{ width: '250px', height: '300px' }">
                    <scrolly-viewport>
                      <div class="technical_data_body_main">
                        <b-card-text>{{ manifestContent[2].content }}</b-card-text>
                      </div>
                    </scrolly-viewport>
                    <scrolly-bar axis="y"></scrolly-bar>
                    <scrolly-bar axis="x"></scrolly-bar>
                  </scrolly>
                </b-card>
              </div>
            </swiper-slide>

            <div class="swiper-pagination" slot="pagination"></div>
          </swiper>
        </div>
      </b-col>
      <b-col cols="0" sm="0" md="0" lg="1" xl="2"/>
    </b-row> -->
  </b-container>
</template>

<script>
import { randomString } from "@/util/string.js";
// import { isSafari } from "@/util/web.js";
// import { sleep } from "@/util/general.js";

import ResizeText from 'vue-resize-text'
import GetJson from "@/repository/json/GetJson.js";

import { vueWindowSizeMixin } from "vue-window-size";
import "swiper/dist/css/swiper.css";
import VueAwesomeSwiper from "vue-awesome-swiper";
const { swiper, swiperSlide } = VueAwesomeSwiper;

import { Scrolly, ScrollyViewport, ScrollyBar } from "vue-scrolly";

const contentJson = GetJson("Manifest", "eng");

// const SCROLL_HIDE_CLASS_NAME = "scroll-hide";

export default {
  data() {
    return {
      main: {},
      options: {
        parentScroll: false
      },
      effects: {
        strongEffect: true,
        blurEffect: true,
        littleEffect: true,
        transform1Effect: true,
        transform2Effect: true,
        partsEffect: true,
        transparentEffect: true
      },
      manifestContent: contentJson,
      swiperOption: {
        slidesPerView: 1,
        spaceBetween: 30,
        pagination: {
          el: ".swiper-pagination",
          type: "fraction"
        },
        navigation: {
          el: ".swiper-pagination",
          clickable: true
        }
      }
    };
  },

  mixins: [vueWindowSizeMixin],

  computed: {
    transform1() {
      const defaultValue = "rson";
      return this.effects.transform1Effect
        ? randomString(defaultValue.length)
        : defaultValue;
    },

    transform2() {
      const defaultValue = "at";
      return this.effects.transform2Effect
        ? randomString(defaultValue.length)
        : defaultValue;
    },
    head_big() {
      return this.contentJson.head_big;
    }
  },

  created() {
    this.toggleEffects()
  },

  methods: {
    toggleEffects() {
      let allDone = false
      let effect = setInterval(() => {
        for (let key in this.effects) {
          if (this.effects[key]) {
            this.effects[key] = false;
            break
          }
          allDone = true
        }
      }, 2000)
      if (allDone) clearInterval(effect)
    },

    clickOnCard(itemId) {
      switch (itemId) {
        case "pool__1":
          this.$refs.pool__1.style.zIndex = 999;
          this.$refs.pool__2.style.zIndex = 99;
          this.$refs.pool__3.style.zIndex = 1;
          break;
        case "pool__2":
          this.$refs.pool__1.style.zIndex = 1;
          this.$refs.pool__2.style.zIndex = 999;
          this.$refs.pool__3.style.zIndex = 99;
          break;
        case "pool__3":
          this.$refs.pool__1.style.zIndex = 99;
          this.$refs.pool__2.style.zIndex = 1;
          this.$refs.pool__3.style.zIndex = 999;
          break;
        default:
          break;
      }
    }
  },
  components: {
    swiper,
    swiperSlide,
    Scrolly,
    ScrollyViewport,
    ScrollyBar
  },
  directives: {
    ResizeText
  }
};
</script>

<style lang="scss" scoped>
.technical_data_body_main {
  padding-right: 15px;
}
.scrolly-viewport {
  position: initial !important;
}
.mb-0::after {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  border: 2px solid;
  border-radius: 10px;
  right: 13px;
  top: 13px;
}

.scroll-area {
  position: relative;
  margin: auto;
  width: 100%;
  height: 300px;
}
$title-font-size: 50px;
html {
  touch-action: none;
}

.swiper-container {
  width: 100%;
  height: 100%;
  margin: 0 auto;
}
.swiper-slide {
  margin-left: 14px;
}

.manifest__main {
  padding-top: 30px;
  min-height: calc(100vh - 50px) !important;
}
.manifest__main > * {
  display: inline;
}

.manifest__title,
.manifest__note {
  color: $violet;
}

.manifest__title {
  // font-size: $title-font-size;
  font-family: "Roboto Regular";
  font-size: 7vmin;
  letter-spacing: -1.4px;
  line-height: 1;
}

.manifest__note {
  padding-bottom: 20px;
  line-height: 1;
}

.manifest__content {
  color: $blue;
  font-family: "Roboto Regular";
  font-size: 7vh;
  letter-spacing: -1.4px;
  line-height: 1;
}

.manifest__strong {
  font-weight: bold;
}

.manifest__blur {
  -webkit-filter: blur(3px);
  filter: blur(3px);
}

.manifest__little {
  font-size: 0.7em;
}

.manifest__transparent {
  opacity: 0.2;
}

.manifest__blue__pool {
  height: auto;
  padding: 80px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-bottom: 150px;
  width: 100%;
  justify-content: center;
}
.pool__1 {
  position: relative;
  right: -6%;
  top: 30px;
  max-width: 450px;
}
.pool__1 > .card > .card-header {
  background: #0500ff;
  color: #fff;
}
.pool__2 {
  max-width: 60vh;
  position: relative;
  top: 145px;
  right: 74px;
}
.pool__2 > .card > .card-header {
  background: #e6e8ec;
  color: #0500ff;
}
.pool__3 {
  position: relative;
  right: 20%;
  top: 100px;
  max-width: 430px;
}
.pool__3 > .card > .card-header {
  color: #fff;
}

@media (max-width: 480px) {
  .pool__3 {
    right: 0;
    top: 0;
    max-width: 280px;
  }
  .pool__1 {
    right: 0;
    top: 0;
    max-width: 280px;
  }
}
@media (max-width: 768px) {
  .manifest__blue__pool {
    padding-bottom: 119px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 20px;
  }
  .pool__2 {
    top: 0;
    right: 0;
    padding-bottom: 50px;
  }
  .pool__3 {
    right: 0;
    top: 0;
    max-width: 430px;
  }
  .pool__1 {
    right: 0;
    top: 0;
    max-width: 430px;
  }
  .manifest__content, .manifest__title {
    font-size: 4vh;
  }
  .card {
    max-width: 280px;
  }
}

.card {
  // -webkit-box-shadow: 5px 5px 5px 0px rgba(112, 112, 112, 1);
  // box-shadow: 5px 5px 5px 0px rgba(112, 112, 112, 1);
  // border-radius: 16px;
  // border: 1px solid #707070;
  border: 0px !important;
  border-radius: 16px;
  box-shadow: 6px 6px 0px 0px #3d464f;
}

.card-header {
  background: #ad83ff;
  font-family: "Roboto Regular";
  text-transform: uppercase;
  border: solid 1px #3d464f !important;
  border-radius: 16px 16px 0 0 !important;
}
.card-title {
  font-family: "Good Sans Regular";
  font-size: 20px;
}

.card-body {
  border: solid 1px #3d464f !important;
  border-top: 0 !important;
  border-radius: 0 0 16px 16px !important;
}

.card-text {
  font-family: "Good Sans Regular";
  font-size: 15px;
}

.section-background {
  background-color: #ccdae8;
}


</style>
